import { CareAnalytics, Config, EventPayload, setUserProps } from '@care/amplitude';
import getConfig from 'next/config';
import { captureException } from '@sentry/nextjs';
import Cookies from 'universal-cookie';
import { getRxDataFromCookie } from '@homepay/rx';
import { NextConfig } from '@/interfaces';
import { CZEN_SESSION_COOKIE_KEY, CZEN_VISITOR_COOKIE_KEY } from '@/constants';
import { Member } from '../API/member';

const { publicRuntimeConfig } = getConfig() as NextConfig;

interface AmplitudeCommonInitData {
  czen_session_id: CZENSessionId;
  environment: string;
  language: string;
  referer: string;
  rendered_by: string;
  userAgent: string;
  visitor_id: CZENVisitorId;
}

interface RxData {
  rx1: string | null;
  rx2: string | null;
  rx3: string | null;
  rx4: string | null;
  rx5: string | null;
  rx6: string | null;
  rx7: string | null;
  rx8: string | null;
  rx9: string | null;
  rx10: string | null;
}

interface AmplitudeCommonData extends RxData {
  screen_resolution: string;
  url: string;
  viewport_resolution: string;
  web_platform: WebPlatform;
}

export interface PageProperties {
  mfe_page_id: string;
  screen_name: string;
  screen_template: string;
  seo_page_id?: number;
  url_path: string;
  partnerName?: string;
  marketingPath?: string;
  failureReason?: string;
}

interface Payload extends EventPayload {
  name: AmplitudeEventName;
  pageProperties: PageProperties;
}

type CZENVisitorId = string;
type CZENSessionId = string;

export enum AmplitudeEventName {
  clickHomepayBudgetCalculator = 'Click Homepay Budget Calculator',
  clickHomepayPaycheckCalculator = 'Click Homepay Paycheck Calculator',
  ctaInteracted = 'CTA Interacted',
  homePayEnrollmentStarted = 'HomePay Enrollment Started',
  homePayLeadCreated = 'HomePay Lead Created',
  screenViewed = 'Screen Viewed',
  slotSent = 'Slot Sent',
  testExposure = 'Test Exposure',
}

export enum WebPlatform {
  DT = 'Desktop Web',
  MW = 'Mobile Web',
}

let initialized = false;

namespace AnalyticsHelper {
  const getCommonInitData = () => {
    const cookies = new Cookies();
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const czenVisitorId: CZENVisitorId =
      cookies.get(CZEN_VISITOR_COOKIE_KEY) ?? 'VisitorIdNotFound';
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const czenSessionId: CZENSessionId =
      cookies.get(CZEN_SESSION_COOKIE_KEY) ?? 'SessionIdNotFound';

    const commonInitData: AmplitudeCommonInitData = {
      czen_session_id: czenSessionId,
      environment: publicRuntimeConfig.SENTRY_ENV,
      language: navigator.language,
      referer: document.referrer,
      rendered_by: 'hp-vhp-mfe',
      userAgent: navigator.userAgent,
      visitor_id: czenVisitorId,
    };

    return commonInitData;
  };

  const initialize = async () => {
    const careAnalytics: CareAnalytics = await import('@care/amplitude');

    if (!initialized) {
      const careAnalyticsConfig: Config = {
        analyticsEnabled: publicRuntimeConfig.ANALYTICS_ENABLED === 'true',
        commonData: getCommonInitData(),
        key: publicRuntimeConfig.AMPLITUDE_API_KEY,
      };

      careAnalytics.init(careAnalyticsConfig);
      initialized = true;
    }
  };

  const getCommonData = () => {
    const screenResolution: string = `${window.screen.width} X ${window.screen.height}`;
    const viewportResolution: string = `${window.innerWidth} X ${window.innerHeight}`;
    const webPlatform: WebPlatform = window.innerWidth <= 960 ? WebPlatform.MW : WebPlatform.DT;

    const commonData: AmplitudeCommonData = {
      screen_resolution: screenResolution,
      url: document.documentURI,
      viewport_resolution: viewportResolution,
      web_platform: webPlatform,
      ...getRxDataFromCookie(),
    };

    return commonData;
  };

  export const logEvent = async (payload: Payload): Promise<void> => {
    try {
      const careAnalytics: CareAnalytics = await import('@care/amplitude');
      initialize();

      const logEventPayload = {
        data: { ...payload.data, ...payload.pageProperties, ...getCommonData() },
        name: payload.name,
      };

      careAnalytics.logEvent(logEventPayload);
    } catch (e) {
      captureException(e);
    }
  };

  export const setDefaultUserProps = (member: Member) => {
    // eslint-disable-next-line unicorn/prefer-global-this
    if (typeof window !== 'undefined' && publicRuntimeConfig.ANALYTICS_ENABLED === 'true') {
      try {
        initialize();

        const userProps = {
          user_id: member.member.id.split('_')[0],
        };
        setUserProps({
          props: userProps,
        });
      } catch (e: any) {
        captureException(e);
      }
    }
  };
}
export const logAnalyticsEvent = (payload: Payload) => AnalyticsHelper.logEvent(payload);
export const setUserId = (member: Member) => AnalyticsHelper.setDefaultUserProps(member);
