/* eslint-disable unicorn/prefer-global-this */
import {
  ApolloClient,
  ApolloLink,
  GraphQLRequest,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
} from '@apollo/client';
import { LogObject } from '@care/utils';
import { SentryLink } from 'apollo-link-sentry';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { RetryFunction } from '@apollo/client/link/retry/retryFunction';
import getConfig from 'next/config';
import { SourceLocation } from 'graphql';
import merge from 'deepmerge';
import { equals } from 'ramda';
import { useMemo } from 'react';
import { captureException, captureMessage, withScope } from '@sentry/nextjs';
import { IAuthService } from '@care/auth';
import { MAX_ATTEMPTS } from '@/constants';
import { ErrorData, InfoData } from '@/types/common';
import { CustomHeaders, NextConfig } from '@/interfaces';
import logger from '@/utils/clientLogger';

const { publicRuntimeConfig, serverRuntimeConfig } = getConfig() as NextConfig;

export type Grecaptcha = {
  enterprise: {
    execute: (siteKey: string, options: { action: string }) => Promise<string>;
    ready: (callback: () => Promise<any>) => any;
  };
};

type ContextObject = {
  headers?: Object;
};

// grecaptcha name comes from the official google recaptcha documentation
declare const grecaptcha: Grecaptcha;
const getGrecaptcha = (
  googleSiteKey: string,
  operationName: string,
  headers: ContextObject | undefined
): Promise<ContextObject> => {
  return new Promise((resolve) => {
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(googleSiteKey, { action: operationName });
      const retHeaders = {
        ...headers,
        'x-care.com-recaptcha-sitekey': googleSiteKey,
        'x-care.com-recaptcha-token': token,
      };
      resolve({ headers: retHeaders });
    });
  });
};

const withRecaptchaToken = (googleSiteKey: string, recaptchaEnabledOperations: string[]) =>
  setContext(async (request, previousContext: ContextObject) => {
    const { headers } = previousContext;
    const operationName = request.operationName ?? '';
    if (typeof grecaptcha !== 'undefined' && recaptchaEnabledOperations.includes(operationName)) {
      try {
        return await getGrecaptcha(googleSiteKey, operationName, headers);
      } catch (error) {
        captureException(error);
      }
    }
    return { headers };
  });

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<NormalizedCacheObject>;

export type LogError = (errorData: ErrorData) => void;
export type LogInfo = (infoData: InfoData) => void;

interface ApolloOptions {
  authService?: IAuthService | null;
  initialState?: {};
  logError?: LogError;
  logInfo?: LogInfo;
}

export const createApolloClient = (options?: ApolloOptions) => {
  const logInfo = options?.logInfo;
  const ssrMode = typeof window === 'undefined';
  const graphqlUrl = ssrMode ? serverRuntimeConfig.GRAPHQL_URL : publicRuntimeConfig.GRAPHQL_URL;
  const { authService } = options ?? {};

  const authLink = setContext((operation: GraphQLRequest, { headers }) => {
    const authData = authService?.getStore();
    let customHeaders: CustomHeaders = {};
    if (
      operation.operationName === 'Member' &&
      authData &&
      authData.access_token &&
      !authData.expired
    ) {
      customHeaders = {
        authorization: `Bearer ${authData.access_token}`,
      };
    }

    const finalHeaders = {
      headers: {
        ...headers,
        ...customHeaders,
      } as Headers,
    };

    return finalHeaders;
  });

  const httpLink = new HttpLink({
    uri: graphqlUrl,
  });

  const getLocationsString = (locations: readonly SourceLocation[] | undefined) => {
    let locationString = '';

    if (locations) {
      locationString = locations
        .map(({ column, line }) => `Line ${line} - Column ${column}`)
        .join(' | ');
    }

    return locationString;
  };

  /**
   * Returns true if the provided error is a retryable network error and we've not exhausted the maximum number of attempts,
   * false otherwise.
   * @param attempts
   * @param operation
   * @param error
   */
  const shouldRetry: RetryFunction = (attempts, operation, error) => {
    const isNetworkError = error instanceof TypeError; // TypeError is the type thrown by `fetch` for network-related errors
    const retryable = isNetworkError && attempts < MAX_ATTEMPTS;

    if (retryable) {
      withScope((scope) => {
        scope.setLevel('info');
        scope.setExtra('errorStack', error.stack);
        scope.setTag('attempts', attempts);
        scope.setTag('errorMessage', error.message);
        scope.setTag('errorName', error.name);
        scope.setTag('operation', operation.operationName);

        captureMessage('Retryable network failure');
      });
    }

    return retryable;
  };

  const getPathString = (pathArray: readonly (string | number)[] | undefined) => {
    let pathString = '';
    if (pathArray) {
      pathString = pathArray.map((path) => `${path}`).join(' | ');
    }
    return pathString;
  };

  const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
    const elapsed = Math.floor(Date.now() - operation.getContext().start);

    const gqlPayload = {
      elapsed,
      variables: operation.variables,
    };

    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path, extensions = {} }) => {
        captureMessage(
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          `[GraphQL error]: Message: ${JSON.stringify(message)}, Location: ${JSON.stringify(
            locations
          )}, Path: ${JSON.stringify(path)}`
        );
        const pathString = getPathString(path);
        const locationString = getLocationsString(locations);
        logger.error({
          event: 'GQLError',
          key: operation.operationName,
          location: locationString,
          message,
          path: pathString,
          payload: gqlPayload,
          serverError: {
            ...extensions,
          },
          source: 'apollo-client.ts',
        });
      });
    }

    if (networkError) {
      captureException(networkError);
      const { statusCode } = networkError as Partial<ServerError>;
      const errorInfo: LogObject = {
        event: 'GQLNetworkError',
        message: `Error calling ${operation.operationName}: ${networkError.message}`,
        payload: gqlPayload,
        serverError: { ...networkError },
        source: 'apollo-client.tsx',
      };
      if ([401, 403].includes(statusCode!)) {
        logger.error({
          key: 'AuthError',
          ...errorInfo,
        });
      } else {
        logger.error({
          key: 'NetworkError',
          ...errorInfo,
        });
      }
    }
  });

  const roundTripLink = new ApolloLink((operation, forward) => {
    // Called before operation is sent to server
    operation.setContext({ start: Date.now() });

    return forward(operation).map((data) => {
      // Called after server responds
      const time = Date.now() - operation.getContext().start;
      const infoData: InfoData = {
        duration: time,
        infoMsg: 'GraphQL request completed',
        operationName: operation.operationName,
      };

      logInfo?.({
        msg: '[GraphQL info]: Operation Round Trip',
        ...infoData,
      });
      return data;
    });
  });

  // Augments the Sentry messages we are getting with more detail
  const sentryLink = new SentryLink({
    attachBreadcrumbs: {
      includeError: true,
      includeFetchResult: false,
      includeQuery: true,
      includeVariables: false,
      transform: (breadcrumb, operation) => {
        if (breadcrumb.category === 'graphql.undefined') {
          const modifiedBreadcrumb = breadcrumb;
          modifiedBreadcrumb.category = `graphql.${operation.operationName}`;
          return modifiedBreadcrumb;
        }

        return breadcrumb;
      },
    },
    setTransaction: true,
    uri: publicRuntimeConfig.GRAPHQL_URL,
  });

  const recaptchaEnabledOperations = publicRuntimeConfig.RECAPTCHA_ENABLED
    ? ['taxCalculatorResultSend']
    : [];

  const recaptchaLink = withRecaptchaToken(
    publicRuntimeConfig.GOOGLE_SITE_KEY,
    recaptchaEnabledOperations
  );

  const retryLink = new RetryLink({
    attempts: shouldRetry,
  });

  const link = ApolloLink.from([
    authLink,
    roundTripLink,
    errorLink,
    recaptchaLink,
    retryLink,
    sentryLink,
    httpLink,
  ]);

  return new ApolloClient({
    cache: new InMemoryCache(),
    link,
    name: publicRuntimeConfig.APP_NAME,
    ssrMode,
    version: publicRuntimeConfig.APP_VERSION,
  });
};

export function initializeApollo<T extends ApolloOptions>(options?: T): typeof apolloClient {
  const apolloClientState = apolloClient ?? createApolloClient(options);

  if (options?.initialState) {
    const existingCache = apolloClientState.extract();

    const data = merge(options.initialState, existingCache, {
      arrayMerge: (destinationArray: unknown[], sourceArray: unknown[]) => [
        ...sourceArray,
        // TODO: fix types
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        ...destinationArray.filter((d) => sourceArray.every((s) => !equals(d, s))),
      ],
    });

    apolloClientState.cache.restore(data);
  }
  if (typeof window === 'undefined') return apolloClientState;
  if (!apolloClient) apolloClient = apolloClientState;

  return apolloClientState;
}

interface Props<T> {
  [key: string]: T | NormalizedCacheObject | ApolloOptions;
}

interface PageProps<T> {
  props: Props<T>;
}

export function addApolloState<T>(client: typeof apolloClient, pageProps: PageProps<T>) {
  const pagePropsState = pageProps;
  if (pagePropsState?.props) {
    pagePropsState.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
  }

  return pagePropsState;
}

export function useApollo<T>(pageProps: Props<T>) {
  const state = useMemo(
    () => (pageProps?.[APOLLO_STATE_PROP_NAME] as ApolloOptions) ?? {},
    [pageProps]
  );
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
